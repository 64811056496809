<template>
    <div class="uvsportlight clearfix" style="width: 100%; margin-top:-7px">
        <div class="uv-shape">
            <div class="ribbon">
                <div :class="$i18n.locale === 'bn' ? 'ribbon-title ribbon-title-bn' : 'ribbon-title ribbon-title-en'">{{ $t('externalIncentive.circular') }}:</div>
                <div class="ribbon-content">
                    <marquee
                        style="margin-top:10px"
                        behavior="scroll" direction="left"
                        onmouseover="this.stop();"
                        onmouseout="this.start();">
                        <ul class="navbar-nav bd-navbar-nav flex-row" v-if="circulars.length > 0">
                            <li class="nav-item" v-for="(item, index) in circulars" :key='index'>
                                {{ $i18n.locale === 'bn' ? item.circular_name_bn : item.circular_name}}
                            </li>
                        </ul>
                    </marquee>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import Common from '@/mixins/portal/common'

export default {
  mixins: [Common],
  props: {
      serviceUrl: {
          type: String,
          required: true
      }
  },
  data () {
    return {
        loading: false,
        circulars: [],
        search: {
            status: 1
        }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    async loadData () {
        const params = Object.assign({}, this.search)
        const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/portal/dae-subsidy-circular', params)
        if (result.success) {
            this.circulars = result.data.data
        } else {
            this.circulars = []
        }
    }
  }
}
</script>

<style>
.ribbon {
    font-size: 16px !important;
    width: 100%;
    position: relative;
    background: #04234F;
    color: #fff;
    text-align: center;
    padding: 0em; /* Adjust to suit */
    margin: 0; /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
    display: flex;
    align-items: center;
}
.ribbon-title {
    position: relative;
    float: left;
    padding: 0.6rem 0rem;
    background: #275cab;
    border: 2px solid #04234f;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 10;
    padding-left: 5px;
    width: 180px;
}
.ribbon-title::after {
  content: "";
  position: absolute;
  border-left: 17px solid #275cab;
  border-top: 17px solid transparent;
  border-bottom: 18px solid transparent;
  right: 0;
  top: 0;
  margin-right: -15px;
}
.ribbon-content {
  width: 100%;
  padding: 0 1rem;
  text-align: left;
  overflow: hidden;
}
.ribbon-title-en, .ribbon-title-bn {
  font-size: 12px !important;
}
.ribbon-content marquee ul li {
    padding: 0px 5px !important;
    font-size: 16px;
}
.ribbon-content marquee ul li.nav-item {
    border-left: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
}
.ribbon-content marquee ul li.nav-item:first-child {
    border-left: 0px solid #ddd !important;
}
.ribbon-content marquee ul li.nav-item:last-child {
    border-right: 0px solid #ddd !important;
}
</style>
