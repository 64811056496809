<template>
  <div class="services_section">
    <div class="video_faq_wrapper">
      <div v-if="service.video_url && service.video_url !== null && service.video_url !== 'null'" class="item_wrapper">
        <a :href="service.video_url" target="_blank" class="link_body">
          <i class="fas fa-photo-video"></i>
          <p class="title">{{ $t('agri_portal.video') }}</p>
        </a>
      </div>
      <div v-if="service.manual_attachment" class="item_wrapper" style="border-bottom: 1px solid var(--brand-secondary-color);">
        <!-- <a v-if="service.manual_attachment" :href="commonServiceBaseUrl + 'storage/portaloriginal/' + service.manual_attachment" target="_blank" class="link_body"> -->
        <a :href="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.manual_attachment" target="_blank" class="link_body">
          <i class="fas fa-download"></i>
          <p class="title">{{ $t('agri_portal.manual') }} <br>{{ $t('globalTrans.download') }}</p>
        </a>
      </div>
      <!-- <div class="item_wrapper">
        <router-link to="/portal/faq" class="link_body">
          <i class="far fa-question-circle"></i>
          <p class="title">{{ $t('agri_portal.q_a') }}</p>
        </router-link>
      </div> -->
    </div>
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ checkBn ?  getParentName($route.query.service_type, parentId).name_bn : getParentName($route.query.service_type, parentId).name }}</h2>
      <img src="../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item"><router-link to="/portal/all-services">{{ $t('portal.all_service') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.service_details') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_details_wrapper">
      <b-container>
        <b-row>
          <b-col v-if="loading">
            <Service />
          </b-col>
          <b-col v-else>
            <div class="service_details">
              <h3 class="service_name">{{ checkBn ? service.name_bn : service.name }}</h3>
              <div class="apply_wrapper mt-2">
                <b-row>
                  <b-col :lg="service.pr_attachment_form === 1 ? 8 : 8">

                    <a href="javascript:" @click="setServiceUrl(service.service_url, '/portal/subsidy-circular-list?service_type=orgList&org_id=2&service_id=36')">
                      <IncentiveDaeSubsidyCircular v-if="hasIncentiveSubsidyCircular" :serviceUrl="service.service_url"></IncentiveDaeSubsidyCircular>
                    </a>

                    <a href="javascript:" @click="setServiceUrl(service.service_url, '/portal/incentive-grant-circular-list?service_type=orgList&org_id=4&service_id=36')">
                      <IncentiveGrantCircular v-if="hasIncentiveGrantCircular" :serviceUrl="service.service_url"></IncentiveGrantCircular>
                    </a>

                    <a v-if="hasPubCircular" href="javascript:" @click="setServiceUrl(service.service_url, '/portal/publication-circular-list?service_type=orgList&org_id='+$route.query.org_id+'&service_id='+$route.query.service_id)">
                      <PublicationCircular v-if="hasPubCircular" :serviceUrl="service.service_url"></PublicationCircular>
                    </a>

                    <a v-if="hasResearchCircular" href="javascript:" @click="setServiceUrl(service.service_url, '/portal/research-circular-list?service_type=orgList&org_id='+$route.query.org_id+'&service_id='+$route.query.service_id)">
                      11<ResearchCircular v-if="hasResearchCircular" :serviceUrl="service.service_url"></ResearchCircular>
                    </a>
                    <a v-if="hasLicenseCircularNotice" href="javascript:" @click="setServiceUrl(service.service_url,  '/portal/seed-dealer-circular?service_type=orgList&org_id='+$route.query.org_id+'&service_id='+$route.query.service_id)">
                        <LicenseCircular v-if="hasLicenseCircularNotice" :serviceUrl="service.service_url"></LicenseCircular>
                    </a>
                  </b-col>
                  <b-col :lg="service.pr_attachment_form === 1 ? 4: 4">
                    <a v-if="service.pr_attachment_form" :href="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.form_attachment" class="app_btn btn_download"><i class="fas fa-download"></i>{{ $t('portal.form_download') }}</a>
                    <router-link v-if="hasPubCircular" to="/portal/publication?home=true" class="app_btn send_btn">
                      <slot v-if="!service.apply_or_view">
                        <i class="fas fa-paper-plane"></i> {{ $t('agri_portal.apply') }}
                      </slot>
                      <slot v-else>
                        <i class="fas fa-long-arrow-alt-right"></i> {{ $t('agri_portal.view') }}
                      </slot>
                    </router-link>
                    <a href="javascript:void" v-else-if="service.pr_apply_btn && hasIncentiveGrantCircular" @click="setServiceUrl(service.service_url, '/portal/incentive-grant-circular-list?service_type=orgList&org_id=4&service_id=36')" class="app_btn send_btn">
                      <slot v-if="!service.apply_or_view">
                        <i class="fas fa-paper-plane"></i> {{ $t('agri_portal.apply') }}
                      </slot>
                      <slot v-else>
                        <i class="fas fa-long-arrow-alt-right"></i> {{ $t('agri_portal.view') }}
                      </slot>
                    </a>
                    <a href="javascript:void" v-else-if="service.pr_apply_btn && hasIncentiveSubsidyCircular" @click="setServiceUrl(service.service_url, '/portal/subsidy-circular-list?service_type=orgList&org_id=2&service_id=36')" class="app_btn send_btn">
                      <slot v-if="!service.apply_or_view">
                        <i class="fas fa-paper-plane"></i> {{ $t('agri_portal.apply') }}
                      </slot>
                      <slot v-else>
                        <i class="fas fa-long-arrow-alt-right"></i> {{ $t('agri_portal.view') }}
                      </slot>
                    </a>
                    <a href="javascript:void" v-else-if="service.pr_apply_btn" @click="setServiceUrl(service.service_url)" class="app_btn send_btn">
                      <!-- <i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }} -->
                      <slot v-if="!service.apply_or_view && this.$route.query.org_id == 9 && this.$route.query.service_id == 54">
                        <i class="fas fa-paper-plane"></i> {{ $t('portal.research_proposal') }}
                      </slot>
                      <slot v-else-if="!service.apply_or_view">
                        <i class="fas fa-paper-plane"></i> {{ $t('agri_portal.apply') }}
                      </slot>
                      <slot v-else>
                        <i class="fas fa-long-arrow-alt-right"></i> {{ $t('agri_portal.view') }}
                      </slot>
                    </a>
                  </b-col>
                </b-row>
                <div class="text-left mt-3" v-if="service.id == 99">
                  <div class="faq_wrapper row">
                    <div class="col-sm-4">
                        <div class="card">
                          <div class="card-header custom-card">
                            <h2 class="que_wrapper">
                              <img class="que-icon" src="../../../assets/images/portal/training-calender.png">
                              <button @click="$router.push('/training/training-calendar')" class="btn btn-block text-left p-0" type="button">
                                {{ $i18n.locale == 'en' ? 'Academic Training Calendar' : 'একাডেমিক প্রশিক্ষণ ক্যালেন্ডার' }}
                              </button>
                            </h2>
                          </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card">
                          <div class="card-header custom-card">
                            <h2 class="que_wrapper">
                              <img class="que-icon" src="../../../assets/images/portal/resource-person.png">
                              <button @click="$router.push('/training/resource-person-list')" class="btn btn-link btn-block text-left p-0" type="button">
                                {{ $i18n.locale == 'en' ? 'Resource Person pool of NATA' : 'নাটার রিসোর্স পার্সন পুল' }}
                              </button>
                            </h2>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card_details">
                <div class="card-header">
                  <h5 class="title">{{ $t('portal.service_description') }}</h5>
                </div>
                <div class="card-body">
                  <b-row>
                    <b-col sm="9">
                      <div class="serve_description">
                        <p class="description" v-html="checkBn ? service.description_bn : service.description"></p>
                      </div>
                    </b-col>
                    <b-col v-if="service.pr_image" sm="3">
                      <img v-if="service.image" :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.image" class="img-fluid rounded serve_img" alt="">
                      <img v-else src="../../../assets/images/portal/service.png" class="img-fluid rounded serve_img" alt="">
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="user_counter_wrapper">
          <b-row>
            <b-col v-if="service.pr_service_applied" sm="4">
              <div class="counter">
                <b-card>
                  <p class="amount">{{ $n(service.applied_service) }}
                    <small>{{ $t('portal.person') }}</small>
                  </p>
                  <span class="user_type">{{ $t('portal.applied_for_service') }}</span>
                </b-card>
              </div>
            </b-col>
            <b-col v-if="service.pr_service_received" sm="4">
              <div class="counter">
                <b-card>
                  <p class="amount">{{ $n(service.received_service) }}<small> {{ $t('portal.person') }}</small></p>
                  <span class="user_type">{{ $t('portal.got_the_service') }}</span>
                </b-card>
              </div>
            </b-col>
            <b-col v-if="service.pr_target_customer" sm="4">
              <div class="counter">
                <b-card>
                  <p class="amount">{{ $n(service.target_customer) }}<small> {{ $t('portal.person') }}</small></p>
                  <span class="user_type">{{ $t('portal.target_customer') }}</span>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="service_info_wrapper">
          <b-row>
            <b-col sm="4" v-if="service.pr_service_time">
              <b-card :header="$t('portal.receiving_service')" class="text-center">
                <div class="d-flex align-items-center justify-content-center h-100">
                  <b-card-text class="expiration">{{ checkBn ? service.anual_duration_bn : service.anual_duration }}</b-card-text>
                </div>
              </b-card>
            </b-col>
            <b-col sm="4" v-if="service.pr_fees">
              <b-card :header="$t('portal.required_fees')" class="text-center">
                <b-card-text>
                  <b-list-group>
                    <b-list-group-item v-for="(fee, index) in service.fees" :key="index"><i class="far fa-hand-point-right"></i> {{ checkBn ? fee.name_bn : fee.name }}</b-list-group-item>
                  </b-list-group>
                </b-card-text>
              </b-card>
            </b-col>
            <b-col sm="4" v-if="service.pr_attachment">
              <b-card :header="$t('portal.necessary_documents')" class="text-center">
                <b-card-text>
                  <b-list-group>
                    <b-list-group-item v-for="(attach, index) in service.attachment" :key="index">
                      <i class="far fa-hand-point-right"></i> {{ checkBn ? attach.name_bn : attach.name }}
                      <!-- <span>(সফটকপি)</span> -->
                    </b-list-group-item>
                  </b-list-group>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <b-row v-if="service.pr_service_process">
            <b-col>
                <div class="process_wrapper">
                  <b-card :header="$t('portal.step_to_get_service')">
                    <b-card-text>
                      <img width="auto" height="100px" :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + service.process" />
                    </b-card-text>
                  </b-card>
                </div>
            </b-col>
        </b-row>
        <div class="service_btn_wrapper">
            <a href="javascript:" @click="$router.go(-1)" class="service_btn back_btn"><i class="fas fa-arrow-left"></i> {{ $t('portal.go_back') }}</a>
            <!-- <router-link v-if="service.pr_apply_btn" :to="service.service_url" class="service_btn send_btn"><i class="fas fa-paper-plane"></i> {{ $t('portal.apply_now') }}</router-link> -->
            <a href="javascript:void" v-if="service.pr_apply_btn" @click="setServiceUrl(service.service_url)" class="service_btn send_btn">
              <slot v-if="!service.apply_or_view">
                <i class="fas fa-paper-plane"></i> {{ $t('agri_portal.apply') }}
              </slot>
              <slot v-else>
                <i class="fas fa-long-arrow-alt-right"></i> {{ $t('agri_portal.view') }}
              </slot>
            </a>
        </div>
        <b-row v-if="service.pr_rating">
          <b-col>
            <div class="rating_review_wrapper">
              <b-card header="Ratings & Reviews of Irrigation Scheme" header-tag="header">
                <b-card-text>
                  <b-row>
                    <b-col sm="2">
                      <div class="text-center">
                        <!-- <h4 class="rating_title">Ratings</h4> -->
                        <div class="rating_circle my-2">4.1<sub style="color: #9e9e9e;">/5</sub></div>
                        <div class="stars-outer">
                          <div class="stars-inner"></div>
                        </div>
                        <div style="color: #9e9e9e;"><small>440 Ratings</small></div>
                      </div>
                    </b-col>
                    <b-col sm="4">
                      <div class="progress mt-4" style="height:10px">
                        <div class="progress-bar dark" style="width:70%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:10%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                      <div class="progress mt-3" style="height:10px">
                        <div class="progress-bar dark" style="width:5%;height:10px"></div>
                      </div>
                    </b-col>
                    <b-col sm="1" class="d-none d-sm-block">
                      <div class="row">
                        <h6 class="rating_text rating_text_common">5</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">4</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">3</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">2</h6>
                      </div>
                      <div class="row">
                        <h6 class="rating_text_common">1</h6>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-text>
                <b-card-text>
                  <div class="reviews_wrapper">
                    <h5 class="reviews_title">Reviews</h5>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                    <div class="review_item">
                      <div class="rating_counter">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p class="date">11 Jul 2021</p>
                      <p class="user_wrapper">by Md. Tawhid <span class="verified"><i class="fas fa-user-shield"></i> Verified Purchase</span></p>
                      <p class="comment">ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই। ফল অার্মিওয়ার্ম ভূট্টা ভিন্ন অন্য কোন ফসলে অাক্রমন করে? বাংলাদেশে কোন অঞ্চলে এ পোকার  অাক্রমন দেখা দিয়েছে কিনা? বিস্তারিত জানতে চাই।</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../config/api_config'
import Common from '@/mixins/portal/common'
import Service from '../components/services/loading/service/service'
import IncentiveDaeSubsidyCircular from './incentive-subsidy-circular/Marque'
import IncentiveGrantCircular from './incentive-grant-circular/Marque'
import PublicationCircular from './publication-management/circular/Marque'
import ResearchCircular from './publication-management/research-circular/Marque'
import LicenseCircular from './seed-dealer-circular/Marque'

export default {
  mixins: [Common],
  components: {
    Service,
    IncentiveDaeSubsidyCircular,
    IncentiveGrantCircular,
    PublicationCircular,
    ResearchCircular,
    LicenseCircular
  },
  data () {
    return {
      loading: false,
      parentId: '',
      service: {},
      hasIncentiveGrantCircular: false,
      hasIncentiveSubsidyCircular: false,
      hasPubCircular: false,
      hasResearchCircular: false,
      hasLicenseCircularNotice: false,
      commonServiceBaseUrl: commonServiceBaseUrl
    }
  },
  created () {
    this.setComp()
    this.loadData()
  },
  methods: {
    setAltImg (event) {
      event.target.src = '../../../assets/images/portal/irrigation-scheme.png '
    },
    setComp () {
      if (this.$route.query.customer_type_id) {
        this.parentId = this.$route.query.customer_type_id
      } else if (this.$route.query.service_category_id) {
        this.parentId = this.$route.query.service_category_id
      } else {
        this.parentId = this.$route.query.org_id
      }
    },
    setServiceUrl (serviceUrl, nextRoute = null) {
      if (serviceUrl === '/trainee-trainer-panel/profile') {
        this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
          trainingType: 1,
          isTraner: 1
        })
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('market-directory') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('research-proposal-circular-list') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('training') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('venue-reservation') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('germplasm-request') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('incentive-farmer') !== -1) {
        const headerItem = this.getHeaderItem()
        this.$router.push({ name: 'portal.circular_list', params: { serviceUrl: serviceUrl, headerItem } })
        return
      }
      if (serviceUrl.indexOf('grant-panel') !== -1) {
        const headerItem = this.getHeaderItem()
        this.$router.push({ name: 'portal.grant_circular_list', params: { serviceUrl: serviceUrl, headerItem } })
        return
      }
      if (serviceUrl.indexOf('/license/seed-dealer-circular') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('research-farmer') !== -1) {
         const headerItem = this.getHeaderItem()
         const serviceName = {
           name: this.service.name,
           name_bn: this.service.name_bn
         }
        if (this.hasPubCircular) {
          this.$router.push({ name: 'portal.publication.publication_circular_list', params: { serviceUrl: serviceUrl, headerItem, serviceName: serviceName } })
          return
        }
        if (this.hasResearchCircular) {
          this.$router.push({ name: 'portal.publication.research_circular_list', params: { serviceUrl: serviceUrl, headerItem, serviceName: serviceName } })
          return
        }
      }
      // Virtual Crop Pest Museum
      if (serviceUrl.indexOf('http://service.moa.gov.bd:8095/') !== -1) {
        window.open(serviceUrl, '_blank')
        return
      }
      if (serviceUrl === 'http://www.frs-bd.com/' || serviceUrl === 'http://103.101.199.133/') {
        window.open(serviceUrl, '_blank')
        return
      }
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: serviceUrl,
        externalPanelCurrentServiceUrl: serviceUrl
      })
      this.$router.push('/auth/login')
    },
    getHeaderItem () {
      const headerItem = {
        id: 0,
        type: 'allService'
      }
      if (this.$route.query.service_type === 'orgList') {
        headerItem.id = this.$route.query.org_id
        headerItem.type = 'orgList'
        return headerItem
      }
      if (this.$route.query.service_type === 'customerTypeList') {
        headerItem.id = this.$route.query.customer_type_id
        headerItem.type = 'customerTypeList'
        return headerItem
      }
      if (this.$route.query.service_type === 'categoryList') {
        headerItem.id = this.$route.query.service_category_id
        headerItem.type = 'categoryList'
        return headerItem
      }
      return headerItem
    },
    async loadData () {
      if (this.$route.query.service_id) {
        this.loading = true
        const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service/show', { id: this.$route.query.service_id })
        this.loading = false
        if (result.success) {
          this.service = result.data
          this.setCircularPage(this.service)
        }
      } else {
        this.$router.push('/portal/home')
      }
    },
    setCircularPage (service) {
      if (service.component_id === 4) {
        if (service.service_url === '/grant-panel/application-form-main') {
          this.hasIncentiveGrantCircular = true
        }
        if (service.service_url.indexOf('/incentive-farmer/subsidy-application-form') !== -1) {
          this.hasIncentiveSubsidyCircular = true
        }
      }
      if (service.component_id === 3) {
        if (this.service.name.indexOf('Magazine') !== -1) {
          this.hasPubCircular = true
          this.hasResearchCircular = false
        }
        if (this.service.name.indexOf('Annual Research') !== -1) {
          this.hasPubCircular = false
          this.hasResearchCircular = true
        }
      }
      if (service.component_id === 2) {
        if (this.service.name.indexOf('Seed Dealer Enlistment Application') !== -1) {
          this.hasLicenseCircularNotice = true
        }
      }
    }
  }
}
</script>
<style scoped>
  .custom-card:hover {
    color: var(--brand-secondary-color);
    background: transparent;
    border: 1px solid var(--brand-secondary-color);
    box-shadow: 0 2px 10px 0px rgba(34, 34, 34, 0.5);
  }
  /* .que_wrapper > button:hover {
    text-decoration: underline !important;
  } */
  .que_wrapper .que-icon{
    /* width: 100%; */
    max-width: 40px;
    padding-right: 5px;
  }
</style>
